var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Brands"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Brand Name"}},[_c('b-form-input',{attrs:{"placeholder":"Please Enter Brand Name"},model:{value:(_vm.brandName),callback:function ($$v) {_vm.brandName=$$v},expression:"brandName"}})],1)],1),_c('b-col',{staticClass:"text-center",attrs:{"md":"2"}},[_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"block":"","variant":"primary"},on:{"click":_vm.addUpdateBrands}},[_vm._v(_vm._s(_vm.addUpdateButton)+" ")])],1)],1),_c('hr'),_c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
                    enabled: true,
                    externalQuery: _vm.searchTerm
                },"select-options":{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
},"pagination-options":{
    enabled: true,
    perPage: _vm.pageLength
}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.editButton(props.row.id, props.row.name)}}},[_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.deleteBrands(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"TrashIcon"}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '50','100']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }